import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Architects = () => {
    return (
        <Layout>
                            <Seo
      title="Architects - BIKESAFE"
      description="Bike Safe offers a complete range of products that combine high security with aesthetic, sculptural designs at reasonable cost. Architects and site planners will find every Bike Safe product consistent with their own high standards for quality and function."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Architects
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Quality and Function:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Bike Safe offers a complete range of products that combine high security with aesthetic, sculptural designs at reasonable cost. Architects and site planners will find every Bike Safe product consistent with their own high standards for quality and function.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/projects.png" className="shadow fluid rounded " />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">In house CAD design:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Bike Safe offers a full design service which includes a site visit and a full CAD design service. Stephen our CAD designer has vast experience in helping architects come up with innovative, unique designs. Please ask for further details on prices if you are interested.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Architects;